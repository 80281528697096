import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  connect() {
    const toasts = document.querySelectorAll(".toast")
    toasts.forEach(function (element) {
      new Toast(element).show()
    })
  }
}
