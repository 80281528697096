import { Controller } from "@hotwired/stimulus"
import List from "list.js"

export default class extends Controller {
  // Init searchable lists
  connect() {
    new List("lists", {
      valueNames: [this.data.get("values")],
      listClass: "list"
    })
  }
}
