import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
export default class extends Controller {
  static targets = [
    "slots",
    "slot",
    "dateFilter",
    "typeFilter",
    "detail",
    "table",
    "availabilityFilter",
    "breakFilter",
    "request",
    "requestNotice"
  ]

  connect() {
    this.filter()
  }

  // Filters open/assigend slots by reloading page with filter param
  filter(event) {
    if (!this.hasTypeFilterTarget || !this.hasDateFilterTarget) return
    const controller = this
    fetch(
      this.data.get("slotsUrl") +
        "?filter=" +
        this.typeFilterTarget.value +
        "&date=" +
        this.dateFilterTarget.value
    )
      .then((response) => response.json())
      .then(function (response) {
        // Render slots
        controller.slotsTarget.innerHTML = response.slots

        // Render detail
        controller.detailTarget.innerHTML =
          '<div class="card-body">Kein Slot ausgewählt</div>'

        // Render table
        controller.tableTarget.innerHTML = ""

        // If an init slot is provied, simulate click on the slot
        if (controller.data.get("initSlotId")) {
          controller.slotTargets
            .find(
              (slot) =>
                slot.getAttribute("data-id") ==
                controller.data.get("initSlotId")
            )
            .click()
        }
      })
  }

  filterRequests(event) {
    Turbo.visit(event.target.value, { action: "replace" })
  }

  resetDatepicker(event) {
    this.dateFilterTarget.value = ""
    this.filter()
  }

  // Selects a slot
  selectedSlot(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Render partials
    this.detailTarget.innerHTML = response.detail
    this.tableTarget.innerHTML = response.table

    // Remove and add selected states
    this.slotTargets.forEach((target) => {
      target.classList.remove("assignment--active")
    })
    event.target.classList.add("assignment--active")
  }

  // Callback after assignment happened
  assigned(event) {
    // Render details
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.detailTarget.innerHTML = response.detail

    // Rerender slot
    this.slotsTarget.querySelector(
      "[data-id='" + response.slot_id + "']"
    ).outerHTML = response.slot

    // Add selected state
    this.slotsTarget
      .querySelector("[data-id='" + response.slot_id + "']")
      .classList.add("assignment--active")

    // Get offer type
    const table = this.tableTarget.querySelector("[data-offer-type]")
    const offer_type = table.getAttribute("data-offer-type")

    // For internal offer types, any number of guides can be assigend
    if (offer_type == "internal") {
      event.target.classList.add("d-none")

      // For all other types, only one type should be assignable
    } else {
      this.tableTarget.querySelectorAll("[data-user-id]").forEach((elem) => {
        elem.classList.add("d-none")
      })
    }
  }

  // Callback after assignment was dismissed
  dismissed(event) {
    // Render details
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.detailTarget.innerHTML = response.detail

    // Update slots
    const active = this.slotsTarget.getElementsByClassName("assignment--active")
    active[0].outerHTML = response.slot

    // Get offer type
    const table = this.tableTarget.querySelector("[data-offer-type]")
    const offer_type = table.getAttribute("data-offer-type")

    // For internal offer types, any number of guides can be assigend
    if (offer_type == "internal") {
      const selector = "[data-user-id='" + response.user_id + "']"
      this.tableTarget.querySelector(selector).classList.remove("d-none")

      // For all other types, only one type should be assignable
    } else {
      this.tableTarget.querySelectorAll("[data-user-id]").forEach((elem) => {
        elem.classList.remove("d-none")
      })
    }
  }

  // When syncing to AOS fails (e.g. because there is already a shift),
  // we display an error modal
  failed(event) {
    new Modal(document.getElementById("assignmentFailedModal")).show()
  }

  // Callback after toggled ignore-availability or only-external-guides
  updatedTable(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    const table_controller =
      this.application.getControllerForElementAndIdentifier(
        this.tableTarget.querySelector('[data-controller="table"]'),
        "table"
      )
    table_controller.table.setData(response)

    // Update availbility filter
    let url = new URL(this.availabilityFilterTarget.getAttribute("data-url"))
    url.searchParams.set("ignore_breaks", this.breakFilterTarget.checked)
    this.availabilityFilterTarget.setAttribute("data-url", url.toString())

    // Update breaks filter
    url = new URL(this.breakFilterTarget.getAttribute("data-url"))
    url.searchParams.set(
      "ignore_availability",
      this.availabilityFilterTarget.checked
    )
    this.breakFilterTarget.setAttribute("data-url", url.toString())
  }

  // When clicking on edit link, update notice in modal
  updateRequestNotice(event) {
    this.requestNoticeTarget.value = event.target.getAttribute("data-notice")
  }

  // Callback whenever a request was toggled or its notice was updated
  updatedRequest(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    const old_request = this.requestTargets.filter(
      (request) =>
        request.getAttribute("data-assignment-slot-id") == response.slot_id
    )[0]
    const new_request = document
      .createRange()
      .createContextualFragment(response.request)
    old_request.parentNode.replaceChild(new_request, old_request)

    // Why does .hide() not work? For now, we replaced it with a custom solution
    // new Modal(document.getElementById('requestNoticeModal')).hide()
    document.getElementById("requestNoticeModal").classList.remove("show")
    if (document.getElementsByClassName("modal-backdrop").length)
      document.getElementsByClassName("modal-backdrop")[0].remove()
  }
}
