import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["elements", "addElement", "elementInput"]

  // Adds an element
  addedElement(event) {
    const [data, status, xhr] = event.detail
    this.elementsTarget.insertAdjacentHTML(
      "beforeend",
      JSON.parse(xhr.response).element
    )
    this.elementInputTargets.pop().focus()
    const link = this.addElementTarget.getAttribute("href")
    const index = Number(link.split("index=")[1])
    const newLink = link.replace(/index=(\d+)/, "index=" + (index + 1))
    this.addElementTarget.setAttribute("href", newLink)
  }

  // Removes an element
  removeElement(event) {
    const element = event.target.closest(".element")
    this.elementsTarget.removeChild(element)
  }
}
