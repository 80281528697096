import { Controller } from "@hotwired/stimulus"

// Import choices.js
import Choices from "choices.js"

export default class extends Controller {
  connect() {
    if (this.element.dataset.initialized) return
    this.element.dataset.initialized = true

    // Set no results text
    let noResultsText = null
    let noChoicesText = null
    if (document.querySelector('meta[name="language"]').content == "de") {
      noResultsText = "Keine Ergebnisse gefunden"
      noChoicesText = "Keine weiteren Einträge"
    } else {
      noResultsText = "No results found"
      noChoicesText = "No more choices"
    }

    // There are two possibilities of initializing a select
    // The first one is simply without any options except a placeholder
    this.choices = new Choices(this.element, {
      classNames: {
        containerInner: this.element.className,
        input: "form-control",
        inputCloned: "form-control-sm",
        listDropdown: "dropdown-menu",
        itemChoice: "dropdown-item",
        activeState: "show",
        selectedState: "active",
        list: "choices__list"
      },
      removeItemButton: this.element.hasAttribute("multiple"),
      shouldSort: false,
      shouldSortItems: false,
      allowHTML: true,
      searchResultLimit: 10,
      noChoicesText: noChoicesText,
      searchChoices: !this.element.dataset.url,
      noResultsText: noResultsText,
      searchPlaceholderValue: this.element.getAttribute("placeholder")
    })

    let choices = this.choices

    // Whenever a data-url is provided, use ajax for searching
    // Also print additional server response field as data-attributes into
    // the selected option. E.g. this is used to prefill user address fields
    // whenever a school is chosen
    if (
      this.element.dataset.url &&
      (this.element.name === "user[organization_name]" ||
        this.element.name === "bookings[booked_for_school_number]")
    ) {
      const url = this.element.dataset.url

      choices.passedElement.element.addEventListener(
        "search",
        function (event) {
          fetch(url + "?query=" + event.detail.value).then((response) => {
            if (response.status !== 200) {
              return
            }
            response.json().then(function (data) {
              const items = data.map(function (item) {
                return {
                  value: item.id,
                  label: item.text,
                  customProperties: {
                    street: item.street,
                    postalcode: item.postalcode,
                    location: item.location,
                    country: item.country
                  }
                }
              })
              choices.clearStore()
              choices.setChoices(items)
            })
          })
        },
        false
      )

      const userController =
        this.application.getControllerForElementAndIdentifier(
          document.querySelector('[data-controller*="user"]'),
          "user"
        )
      choices.passedElement.element.addEventListener(
        "choice",
        function (event) {
          if (
            userController &&
            Object.keys(event.detail.choice.customProperties).length > 0
          ) {
            userController.selectedSchool(event.detail.choice.customProperties)
          }
        },
        false
      )
    }
  }

  clearValue() {
    this.choices.setChoiceByValue("")
  }

  setValue(value) {
    this.choices.setChoiceByValue(value)
  }

  enableChoices() {
    this.element.closest(".choices").classList.remove("is-disabled")
  }

  disableChoices() {
    this.element.closest(".choices").classList.add("is-disabled")
  }
}
