import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errors", "form", "button"]

  connect() {
    // Get new heidelpay instance (now named Unzer)
    let heidelpayInstance = new heidelpay(this.data.get("public-key"), {
      locale: this.data.get("locale")
    })

    // Prepare credit card form
    if (this.data.get("method") == "creditcard") {
      this.method = heidelpayInstance.Card()

      // Rendering input fields
      this.method.create("number", {
        containerId: "card-element-id-number",
        onlyIframe: false
      })
      this.method.create("expiry", {
        containerId: "card-element-id-expiry",
        onlyIframe: false
      })
      this.method.create("cvc", {
        containerId: "card-element-id-cvc",
        onlyIframe: false
      })

      // Prepare Paypal form
    } else if (this.data.get("method") == "paypal") {
      this.method = heidelpayInstance.Paypal()
      this.submit()

      // Prepare EPS form
    } else if (this.data.get("method") == "eps") {
      this.method = heidelpayInstance.EPS()
      this.submit()
    }
  }

  submit(event) {
    if (event != undefined) {
      event.preventDefault()
      this.buttonTarget.setAttribute("disabled", "disabled")
    }

    // Hide errors and disable submit button
    this.errorsTarget.classList.add("d-none")

    // Send Unzer ressource preparation and handle prepared ressource id
    this.method
      .createResource()
      .then((response) => {
        this.processPayment(response["id"])
      })
      .catch((error) => {
        this.errorsTarget.innerText = error.message
        this.errorsTarget.classList.remove("d-none")
        if (this.hasButtonTarget) this.buttonTarget.removeAttribute("disabled")
      })
  }

  processPayment(unzer_resource_id) {
    // Get CSRF token
    const csrf_token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content")

    // Get Token for reservations if available
    let token = null
    if (document.getElementById("token"))
      token = document.getElementById("token").value

    // Send unzer ressource id to our server
    fetch(this.data.get("url"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token
      },
      body: JSON.stringify({
        unzer_resource_id: unzer_resource_id,
        token: token
      })

      // Redirect user according to server response
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) Turbo.visit(response.url)
        else {
          this.errorsTarget.innerText = response.error
          this.errorsTarget.classList.remove("d-none")
          if (this.hasButtonTarget)
            this.buttonTarget.removeAttribute("disabled")
        }
      })
  }
}
