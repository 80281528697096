import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove(event) {
    const entry = event.target.closest(".seasonal-opening-time")
    entry.remove()
  }

  duplicate(event) {
    const entry = event.target.closest(".seasonal-opening-time")
    entry.insertAdjacentHTML("afterend", entry.outerHTML)
    const newEntry = entry.nextSibling

    // This fixes dupliate flatpickrs, can be done better?
    newEntry.querySelectorAll(".time-input").forEach((target) => {
      target.querySelector(".flatpickr-calendar:last-child").remove()
    })
  }
}
