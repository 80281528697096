import { Controller } from "@hotwired/stimulus"
import Pickr from "@simonwep/pickr"

export default class extends Controller {
  static targets = ["picker", "input"]

  connect() {
    Pickr.create({
      el: this.pickerTarget,
      theme: "monolith",

      swatches: [
        "rgba(244, 67, 54, 1)",
        "rgba(233, 30, 99, 1)",
        "rgba(156, 39, 176, 1)",
        "rgba(103, 58, 183, 1)",
        "rgba(63, 81, 181, 1)",
        "rgba(33, 150, 243, 1)",
        "rgba(3, 169, 244, 1)",
        "rgba(0, 188, 212, 1)",
        "rgba(0, 150, 136, 1)",
        "rgba(76, 175, 80, 1)",
        "rgba(139, 195, 74, 1)",
        "rgba(205, 220, 57, 1)",
        "rgba(255, 235, 59, 1)",
        "rgba(255, 193, 7, 1)"
      ],

      components: {
        preview: true,
        opacity: false,
        hue: false,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true
        }
      },

      strings: {
        save: "Übernehmen"
      }

      // Use color on init
    })
      .on("init", (instance) => {
        instance.setColor(this.inputTarget.value)

        // Set new color on save
      })
      .on("save", (color, instance) => {
        this.inputTarget.value = color.toHEXA().toString()
      })
  }
}
