import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Usually the turbo cache can make the sidebar wiggle a bit
  // Makes cosmetic changes, such that this does not happen
  connect() {
    // Close all sections
    this.element.querySelectorAll(".nav-item .collapse").forEach((item) => {
      item.classList.remove("show")
    })

    // Only open active section
    const link = this.element.querySelector(".nav-link.active")
    if (link != null) link.closest(".collapse").classList.add("show")
  }

  navtoggle(event) {
    document.body.classList.toggle("js-nav-visible")
    event.target.closest(".sidebar-toggle").classList.toggle("active")
  }
}
