import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["supplierSelect", "commissionPercentage"]

  connect() {
    this.supplierChanged()
  }

  supplierChanged(event) {
    console.log(this.supplierSelectTarget)
    const select = this.supplierSelectTarget
    const option = select.options[select.selectedIndex]
    this.commissionPercentageTarget.classList.toggle(
      "d-none",
      option.getAttribute("data-is-commission-partner") == "false"
    )
  }
}
