import { Controller } from "@hotwired/stimulus"
import "simplycountdown.js/dist/simplyCountdown.min.js"

export default class extends Controller {
  static targets = ["counter"]

  connect() {
    const current_date = new Date()
    const minutes = parseInt(this.data.get("seconds") / 60)
    const seconds = parseInt(this.data.get("seconds") % 60)
    const controller = this

    // Init countdown plugin
    simplyCountdown("#" + this.counterTarget.getAttribute("id"), {
      year: current_date.getFullYear(),
      month: current_date.getMonth() + 1,
      day: current_date.getDate(),
      hours: current_date.getHours(),
      minutes: current_date.getMinutes() + minutes,
      seconds: current_date.getSeconds() + seconds,
      zeroPad: true,
      words: {
        days: "",
        hours: "",
        minutes: { singular: ":", plural: ":" },
        seconds: "",
        pluralLetter: ""
      },
      onEnd: function () {
        controller.element.classList.add("alert alert-danger")
        controller.element.innerHTML = controller.data.get("message")
      }
    })
  }

  disconnect() {
    this.element.innerHTML = ""
  }
}
