import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  static targets = [
    "monthSelect",
    "yearSelect",
    "userSelect",
    "deviationForm",
    "slots",
    "slot"
  ]

  // If month, year or user is changed, reload page with new filter params
  changeFilter(event) {
    // Generate new url, using month, day and user filters
    let url = new URL(window.location)
    url.searchParams.set("month", this.monthSelectTarget.value)
    url.searchParams.set("year", this.yearSelectTarget.value)

    // If user-switch was triggered, use the current target user
    if (event.currentTarget.classList.contains("user-select-link")) {
      url.searchParams.set(
        "user_id",
        event.currentTarget.getAttribute("data-user-id")
      )

      // Else if month or year were switched, use already selected user
    } else {
      url.searchParams.set(
        "user_id",
        this.userSelectTargets
          .filter((link) => link.classList.contains("selected"))[0]
          .getAttribute("data-user-id")
      )
    }

    // Reload page with new parameters
    Turbo.visit(url.toString(), { action: "replace" })
  }

  // Selects a slot
  selectedSlot(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Render partials
    this.deviationFormTarget.innerHTML = response.deviation_form

    // Remove and add selected states
    this.slotTargets.forEach((target) => {
      target.classList.remove("assignment--active")
    })
    event.target.classList.add("assignment--active")
  }

  // Disable some of the UI
  changedTookPlace(event) {
    this.deviationFormTarget
      .querySelector(".card-body")
      .classList.toggle("disabled", !event.target.checked)
  }

  // Callback after saving happened
  savedDeviation(event) {
    // Render details
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Update slots
    const active = this.slotsTarget.getElementsByClassName("assignment--active")
    active[0].outerHTML = response.slot

    // Show notice
    const toastContainer = document.querySelector("[data-controller=toast]")
    toastContainer.innerHTML = response.notice
    const toast = document.querySelector(".toast")
    new Toast(toast).show()
  }
}
